<template>
  <SearchModule />
</template>

<script>
import SearchModule from './SearchModule.vue';
export default {
  name: 'HomePage',
  components: {
    SearchModule,
  },

};
</script>
